import React from "react"
import styled, { keyframes } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { v4 } from "uuid"
import { Section, SectionTitle } from "../Section"
import BreakpointDown from "../Media/BreakpointDown"
import BreakpointUp from "../Media/BreakpointUp"

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const TabWrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  ${BreakpointUp.lg`
        flex-direction: column;
    `}
`
const TabNavThumb = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  max-width: 990px;
  ${BreakpointDown.lg`	
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      text-align:center;
    `}
`

const TabThumb = styled.div`
  position: relative;
  display: block;
  border-radius: 50%;
  transition: all 0.3s ease;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  ${BreakpointUp.lg`
        width: 160px;
        height: 160px;
    `}
  .gatsby-image-wrapper {
    border-radius: 50%;
    z-index: 1;
  }
  &:after {
    content: "";
    position: absolute;
    top: -20px;
    left: -20px;
    background-color: #0b619b;
    border-radius: 50%;
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    transition: cubic-bezier(0.4, 0, 1, 1) 0.3s all;
  }
  &:before {
    content: "";
    position: absolute;
    border-style: solid;
    border-color: transparent transparent transparent transparent;
    opacity: 0;
    visibility: hidden;
    transition: cubic-bezier(0.4, 0, 1, 1) 0.3s all;
    left: calc(50% - 32px);
    bottom: 22px;
    border-width: 32px 32px 0 32px;
    ${BreakpointUp.lg`
            top: 22px;
            bottom:auto;
            border-width: 0 32px 32px 32px;
        `}
  }
`
const TabLink = styled.div`
  color: #B30000 !important;
  font-weight: 700;
  display: block;
  position: relative;
  padding: 40px 0 0 0;
  line-height: 26px;
  text-align: center;
  white-space: normal;
  min-width: 160px;
  max-width: 160px;
  width: 100%;
  ${BreakpointUp.lg`
        padding: 30px 0 0 0;  
        min-width: 200px;
        max-width:200px;
        width:100%;
    `}
`
const TabItem = styled.div`
  position: relative;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  margin: 20px 10px 20px 10px;
  ${BreakpointUp.lg`
        margin: 40px 30px 0 30px;
        display: block;
        
    `}

  &:hover {
    .tab-thumb {
      &:after {
        background-color: #fc0002;
      }
      &:before {
        opacity: 1;
        visibility: visible;
        border-color: #fc0002 transparent transparent transparent;
        transform: translateY(60px);
        ${BreakpointUp.lg`
                    transform: translateY(-60px);
                    border-color: transparent transparent #FC0002 transparent;
                `}
      }
    }
    .tab-link {
      color: #fc0002;
    }
  }
  &.active {
    .tab-thumb {
      &:after {
        background-color: #fc0002;
      }
      &:before {
        opacity: 1;
        visibility: visible;
        transform: translateY(60px);
        border-color: #fc0002 transparent transparent transparent;
        ${BreakpointUp.lg`
                    transform: translateY(-60px);
                    border-color: transparent transparent #FC0002 transparent;
                `}
      }
    }
    .tab-link {
      color: #fc0002;
    }
  }
`
const TabContent = styled.div`
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
  ${BreakpointUp.lg`
        margin:0 auto 40px auto;
    `}
`
const TabPane = styled.div`
  display: ${props => props.display};
  animation-duration: 1s;
  animation-name: ${FadeIn};
  animation-fill-mode: both;
  min-height:92px;
  h4 {
    font-size: 18px;
    line-height: 28px;
    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 30px;
    }
    @media (min-width: 992px) {
      font-size: 24px;
      line-height: 34px;
    }
  }
  p {
    margin-bottom: 0;
    + p {
      margin-top: 30px;
    }
  }
`

class WhyChoose extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTab: 0,
    }
    this.selectTab = this.selectTab.bind(this)
  }
  selectTab(i) {
    this.setState({
      currentTab: i,
    })
  }
  render() {
    const { data } = this.props
    const { currentTab } = this.state
    return (
      <Section
        pt="110px"
        pb="110px"
        xpt="60px"
        xpb="60px"
        bgColor="#F7F7F7"
        className="circle-left"
      >
        <div className="container">
          <SectionTitle data-shadowtext={data.label} mb="30px">
            {data.title}
          </SectionTitle>
        </div>
        <div className="container">
          <TabWrap>
            <TabContent>
              {data.tabData.map((item, i) => (
                <TabPane
                  key={v4()}
                  display={i === currentTab ? "block" : "none"}
                >
                  <h3>{item.title}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.description.childMarkdownRemark.html,
                    }}
                  />
                </TabPane>
              ))}
            </TabContent>
            <TabNavThumb>
              {data.tabData.map((item, i) => {
                return (
                  <TabItem
                    key={v4()}
                    className={i === currentTab ? "active" : ""}
                    onClick={() => this.selectTab(i)}
                  >
                    <TabThumb className="tab-thumb">
                      <GatsbyImage
                        image={item.image.gatsbyImageData}
                        alt={item.label}
                      />
                    </TabThumb>
                    <TabLink className="tab-link">{item.label}</TabLink>
                  </TabItem>
                )
              })}
            </TabNavThumb>
          </TabWrap>
        </div>
      </Section>
    )
  }
}

export default WhyChoose
