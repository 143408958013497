import React from "react"
import styled from "styled-components"
import { v4 } from "uuid"
import { Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import { Section, SectionTitle } from "../Section"
import RightLineArrowIcon from "../Icons/RightLineArrowIcon"
import BreakpointUp from "../Media/BreakpointUp"

const SectionBackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .gatsby-image-wrapper {
    height: 100%;
  }
`

const StepGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-bottom: 12px;
  border-left: 4px solid rgba(255, 255, 255, 0.2);
  padding-left: 20px;
  ${BreakpointUp.lg`      
        border-bottom:4px solid rgba(255,255,255,0.2);
        border-left:none;
        padding-left:0;
    `}
  &:after, &:before {
    position: absolute;
    color: #fff;
    font-size: 18px;
    font-weight: 900;
    line-height: 20px;
    background: #18486c;
    padding: 0 5px;
  }
  &:before {
    content: "Start";
    bottom: auto;
    top: -25px;
    left: -15px;
    ${BreakpointUp.lg`      
            top:auto;
            left:0;
            bottom: -10px;
        `}
  }
  &:after {
    content: "End";
    right: auto;
    left: -15px;
    bottom: -25px;
    ${BreakpointUp.lg`      
            left:auto;
            right:0;
            bottom: -10px;
        `}
  }
`
const StepGridItem = styled.div`
  position: relative;
  ${BreakpointUp.lg`
        margin:0 -2px;      
    `}
  &:nth-child(1) {
    ${BreakpointUp.lg`
            flex: 0 0 25%;
            max-width: 25%;
            margin-top: 30px;
        `}
    &:before {
      height: 15%;
    }
  }
  &:nth-child(2) {
    ${BreakpointUp.lg`       
            flex: 0 0 20%;
            max-width: 20%;
            margin-top: 60px;
        `}
  }
  &:nth-child(3) {
    ${BreakpointUp.lg`
            flex: 0 0 30%;
            max-width: 30%;
        `}
  }
  &:nth-child(4) {
    ${BreakpointUp.lg`
            margin-top: 40px;
            flex: 0 0 25%;
            max-width: 25%;
        `}
    &:before {
      height: 15%;
    }
  }
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
  }

  &:before {
    background-image: linear-gradient(
      to top,
      rgba(249, 249, 249, 0.5) 50%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: center top;
    background-size: 2px 9px;
    background-repeat: repeat-y;
    width: 2px;
    height: 10%;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    display: none;
    ${BreakpointUp.lg`
           display:block;
        `}
  }
  &:after {
    background-color: #0b619b;
    border-radius: 50%;
    transform: translateX(-50%);
    z-index: 2;
    border: 6px solid #fff;
    box-shadow: 0 0 0 6px #18486c;
    width: 20px;
    height: 20px;
    top: 0;
    left: -20px;
    ${BreakpointUp.lg`   
            border: 6px solid #fff;
            box-shadow: 0 0 0 8px #18486c;
            width: 24px;
            height: 24px;         
            top: 100%;
            left: 50%;
        `}
  }
`
const Step = styled.div`
  color: #fff;
`
const StepBody = styled.div`
  padding: 15px 0 30px 0;
  ${BreakpointUp.lg`
        padding:30px 15px 75px 15px;
    `}
  h3 {
    color: #fff;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    @media (min-width: 1200px) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  p {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 22px;
  }
  a {
    color: rgba(255, 255, 255, 0.5);
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    .text {
      text-decoration: underline;
      + .icon {
        margin-left: 10px;
      }
    }
    .icon {
      display: inline-flex;
      align-items: center;
      svg {
        fill: #fff;
      }
    }

    &:hover {
      color: #fff;
      .text {
        text-decoration: none;
      }
    }
  }
`
const StepFigure = styled.figure`
  margin-bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  .gatsby-image-wrapper {
    border: 4px solid #0b619b;
  }
`
const StepNumber = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-weight: 900;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 28px;
  ${BreakpointUp.lg`
        font-size:20px;
        line-height:30px;
    `}
  strong {
    font-size: 50px;
    line-height: 50px;
    font-weight: 900;
    position: relative;
    left: -5px;
    ${BreakpointUp.lg`
            font-size:90px;        
            line-height:90px;
            left:-10px;
        `}
  }
`

const Process = ({ data }) => {
  return (
    <Section pt="110px" pb="110px" xpt="60px" xpb="60px" bgColor="#0B619B">
      <SectionBackgroundImage>
        <StaticImage
          src="../../images/process-section-bg.png"
          alt="process section"
        />
      </SectionBackgroundImage>
      <div className="container-fluid">
        <SectionTitle
          data-shadowtext={data.label}
          mb="30px"
          color="#fff"
          colorBefore="rgba(255,255,255,0.15)"
        >
          {data.title}
        </SectionTitle>
        <StepGrid>
          {data.processes.map((item, i) => (
            <StepGridItem key={v4()}>
              <Step>
                <StepFigure className="step-figure">
                  <GatsbyImage
                    image={item.image.gatsbyImageData}
                    alt={item.image.title}
                  />
                </StepFigure>
                <StepBody>
                  <StepNumber>
                    <span>STEP</span>
                    <strong>0{i + 1}</strong>
                  </StepNumber>
                  <h3>{item.title}</h3>
                  <p>{item.description.description}</p>
                  <Link to={item.buttonLink}>
                    <span className="text">{item.buttonText}</span>
                    <span className="icon">
                      <RightLineArrowIcon />
                    </span>
                  </Link>
                </StepBody>
              </Step>
            </StepGridItem>
          ))}
        </StepGrid>
      </div>
    </Section>
  )
}

export default Process
